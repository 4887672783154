@import "./rankColor.variables.scss";

.Economy {
  background-color: $economy-primary-bg;
  &PrimaryFontColor {
    color: $economy-primary !important;
  }
  &SecondaryFontColor {
    color: $economy-secondary !important;
  }
  &ProgressBar {
    background-color: $economy-primary !important;
    stroke: $economy-primary !important;
  }
}

.Business {
  background-color: $business-primary-bg;
  &PrimaryFontColor {
    color: $business-primary !important;
  }
  &SecondaryFontColor {
    color: $business-secondary !important;
  }
  &ProgressBar {
    background-color: $business-primary !important;
    stroke: $business-primary !important;
  }
}

.First {
  background-color: $first-primary-bg;
  &PrimaryFontColor {
    color: $first-primary !important;
  }
  &SecondaryFontColor {
    color: $first-secondary !important;
  }
  &ProgressBar {
    background-color: $first-primary !important;
    stroke: $first-primary !important;
  }
}

.Vip {
  background-color: $vip-primary-bg;
  &PrimaryFontColor {
    color: $vip-primary !important;
  }
  &SecondaryFontColor {
    color: $vip-secondary;
  }
  &ProgressBar {
    background-color: $vip-primary !important;
    stroke: $vip-primary !important;
  }
}
